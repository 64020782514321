import  { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import http from '../http'
import React  from 'react';
import Clock from 'react-live-clock';
import { useAuthContext } from '../hooks/useAuthContext';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOpts,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Col, Container, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import loadingGif from '../assets/Wedges_locading.gif';

export default function PrimeTrade(){
  let token = '';
  const navigate = useNavigate()

  const { user } = useAuthContext();
  if(user != null){
    token = user.token;
  }else{
    token = ''
    navigate('/login')
  }
  // console.log("User Token---------PRIME Page:", token)

  const [primeItems, setPrimeItems] = useState(["default"]);
  const [analyticalData, setAnalyticalData] = useState([]);
  const today = Date('Y-m-d');
  useEffect(()=>{
    //fetchPrvStoredPrimeTrade(); 
    if (user != null){
      fetchPrimeTrade();
    }
  },[user]);
  
  //console.log("PrimeStateValue: ", primeItems[0])
  
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  const bodyParameters = {
    key: "value"
  };

  const fetchPrimeTrade = () =>{
    http.get('/auth/trade-prm-data', bodyParameters, config).then(res=>{
    // http.get('/auth/trade-prm-data-app', bodyParameters, config).then(res=>{
        setPrimeItems(res.data);
    })
  }

  const fetchAllTrade = () =>{
    http.get('/dse-all', bodyParameters, config).then(resAll=>{
        setAnalyticalData(resAll.data)
    })
  }

const dataTable_items = {
  "SL":'Tiger Nixon',
  "CODE": 'System Architect',
  "TIME":'Edinburgh',
  "LTP": '61',
  "HIGH":'2011/04/25',
  "LOW": '2011/04/25',
  "VOLUME": '2011/04/25',
  // "COMPVOL": '2011/04/25',
  "ACTION":<Link className="btn btn-info" to={`/explore-item/`}>Explore</Link>,
  "clickEvent": ()=> testClickEvent(1),
};

const dataTable = primeItems.map((dataItem, index)=>{
  let items = {
    "SL":++index,
    "CODE": dataItem.trading_code,
    "TIME":dataItem.lead_at,
    "LTP": dataItem.ltp,
    "HIGH":dataItem.current_high_price,
    "LOW": dataItem.day_low,
    "VOLUME":dataItem.current_volume,
    // "COMPVOL": dataItem.y_comparative_volume,
    "ACTION":<Link className="btn btn-info" to={`/explore-item/${dataItem.trading_code}`}>Explore</Link>,
    //"clickEvent": ()=> testClickEvent(1),
  }
  return items;
})
 
// console.log("Major Item List--------:", dataTable)
function testClickEvent(param) {
  alert('Row Click Event');
}

const data = () => {
  return {
    columns: [
      {
        label: 'SL.',
        field: 'SL',
        width: 20,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'SL.',
        },
      },
      {
        label: 'CODE',
        field: 'CODE',
        sort: 'asc',
        width: 250,
      },
      {
        label: 'TIME',
        field: 'TIME',
        width: 250,
      },
      {
        label: 'LTP',
        field: 'LTP',
        width: 30,
      },
      {
        label: 'HIGH',
        field: 'HIGH',
        sort: 'disabled',
        width: 50,
      },
      {
        label: 'LOW',
        field: 'LOW',
        sort: 'disabled',
        width: 50,
      },
      {
        label: 'VOLUME',
        field: 'VOLUME',
        sort: 'disabled',
        width: 100,
      },
      // {
      //   label: 'Y-COMP-VOL',
      //   field: 'COMPVOL',
      //   sort: 'disabled',
      //   width: 100,
      // },
      {
        label: 'ACTION',
        field: 'ACTION',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows:dataTable,
  };
};

const handleAnalyticalEngine = () =>{
  setPrimeItems(["default"]);
  // fetchAllTrade();
  fetchPrimeTrade();
}
//Set Prime Time Limit
const currentDateTime = new Date();
const setPrimeEndTime = new Date();
setPrimeEndTime.setHours(10,8,0);//10:08:00

  return (
    <div className='container' >
            <h2>Prime List</h2>
            <Clock
          date={today}
          format={'dddd(DD-MM-YYYY), h:mm:ss A'}
          ticking={true}
          timezone={'Asia/Dhaka'} />
        {
           currentDateTime < setPrimeEndTime &&
           <>
            <div class="row mt-2">
              <div class="clearfix col-md-12 " >
                <button type="button" class="btn btn-primary" onClick={handleAnalyticalEngine}>Reload Analytic Engine...</button>
              </div>
            </div>
           </>
        }
        

        {
          // primeItems.length === 0 ?
          primeItems[0] === "default" ?
          <>
            <Container className='mt-5'>
              <img className='mt-5' src={loadingGif} alt="loading..." />
              <p>loading...</p>
            </Container>
          </>
          :
          <>
            <CDBContainer style={{ "margin":"10px" }}>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    entriesOptions={[30, 60, 90, 120, 150, 180, 210, 240, 270, 300]}
                    entries={30}
                    pagesAmount={4}
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
          </>
        }
        

        </div>
  )

}
