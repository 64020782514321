import  { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import http from '../http'
import React  from 'react';
import Clock from 'react-live-clock';
import { useAuthContext } from '../hooks/useAuthContext';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOpts,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Col, Container, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import loadingGif from '../assets/Wedges_locading.gif';

export default function MajorTrade(){
  let token = '';
  const navigate = useNavigate()

  const { user } = useAuthContext();
  if(user != null){
    token = user.token;
  }else{
    token = ''
    navigate('/login')
  }
  console.log("User Token---------MAJOR Page:", token)

  const [majorItems, setMajorItems] = useState([]);
  const [analyticalData, setAnalyticalData] = useState([]);

  const today = Date('Y-m-d');
  useEffect(()=>{
    //fetchPrvStoredPrimeTrade(); 
    if (user != null){
      fetchMajorTrade();
    }
  },[user]);
    
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  const bodyParameters = {
    key: "value"
  };

  const fetchMajorTrade = () =>{
    http.get('/auth/trade-mjr-data', bodyParameters, config).then(res=>{
    // http.get('/auth/trade-mjr-data-half-hourly', bodyParameters, config).then(res=>{
        setMajorItems(res.data);
    })
  }
 ;

const dataTable = majorItems.map((dataItem, index)=>{
  let items = {
    "SL":++index,
    "CODE": dataItem.trading_code,
    "TIME":dataItem.lead_at,
    "LTP": dataItem.ltp,
    "HIGH":dataItem.current_high_price,
    "LOW": dataItem.day_low,
    "VOLUME":dataItem.current_volume,
    "COMPVOL": dataItem.y_comparative_volume,
    "SECTOR": dataItem.sector_support + "%",
    "ACTION":<Link className="btn btn-info" to={`/explore-item/${dataItem.trading_code}`}>Explore</Link>,
    //"clickEvent": ()=> testClickEvent(1),
  }
  return items;
})
 
console.log("Major Item List--------:", dataTable)
  function testClickEvent(param) {
    alert('Row Click Event');
  }

  const data = () => {
    return {
      columns: [
        {
          label: 'SL.',
          field: 'SL',
          width: 20,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'SL.',
          },
        },
        {
          label: 'CODE',
          field: 'CODE',
          sort: 'asc',
          width: 250,
        },
        {
          label: 'TIME',
          field: 'TIME',
          width: 250,
        },
        {
          label: 'LTP',
          field: 'LTP',
          width: 30,
        },
        {
          label: 'HIGH',
          field: 'HIGH',
          sort: 'disabled',
          width: 50,
        },
        {
          label: 'LOW',
          field: 'LOW',
          sort: 'disabled',
          width: 50,
        },
        {
          label: 'VOLUME',
          field: 'VOLUME',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'Y-COMP-VOL',
          field: 'COMPVOL',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'SECTOR',
          field: 'SECTOR',
          sort: 'disabled',
          width: 100,
        },
        {
          label: 'ACTION',
          field: 'ACTION',
          sort: 'disabled',
          width: 100,
        },
      ],
      rows:dataTable,
    };
  };

  const handleAnalyticalEngine = () =>{
    setMajorItems([]);
    fetchAllTrade();
    fetchMajorTrade();
  }

  const fetchAllTrade = () =>{
    http.get('/dse-all', bodyParameters, config).then(resAll=>{
        setAnalyticalData(resAll.data)
    })
  }
  // majorItems !==null? (console.log(majorItems, "======== Major Items ")) : (console.log("Nooooooooooooooooo Major Items "))
  const currentDateTime = new Date();

  const setTradeEndTime = new Date();
  setTradeEndTime.setHours(14,20,0)//14:30:00

  return (
    <div className='container' >
            <h2>Major List Trade Information</h2>
            <Clock
          date={today}
          format={'dddd(DD-MM-YYYY), h:mm:ss A'}
          ticking={true}
          timezone={'Asia/Dhaka'} />
        {
          // currentDateTime < setTradeEndTime && 
          <>
             <div class="row mt-2">
                <div class="clearfix col-md-12 " >
                  <button type="button" class="btn btn-primary" onClick={handleAnalyticalEngine}>Reload Analytic Engine...</button>
                </div>
              </div>
          </> 
        }
       

        {
          majorItems.length === 0 ?
          <>
             <Container className='mt-5'>
              <img className='mt-5' src={loadingGif} alt="loading..." />
              <p>loading...</p>

            </Container>
          </>
          :
          <>
            <CDBContainer style={{ "margin":"10px" }}>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    striped
                    bordered
                    hover
                    entriesOptions={[30, 60, 90, 120, 150, 180, 210, 240, 270, 300]}
                    entries={30}
                    pagesAmount={4}
                    data={data()}
                    materialSearch={true}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
          </>
        }
        

        </div>
  )

}
