import logo from './logo.svg';
import './App.css';
import {Container, Button, Badge, Stack} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, Link, useNavigate, BrowserRouter, Switch } from 'react-router-dom';
import Home from './pages/Home'
import Login from './pages/Login'
import Signup from './pages/Signup'
import Edit from './pages/Edit'
import View from './pages/View'
import PrimeTrade from './pages/PrimeTrade';
import MajorTrade from './pages/MajorTrade';
import Dashboard from './pages/Dashboard';
import { useEffect, useState } from 'react';
import Header from './components/Header';
import ExploreItem from './pages/ExploreItem';
import ItemExploreModal from './pages/ItemExploreModal';
// import Header from './components/Header';
// import useToken from './components/useToken';




// function setToken(userToken) {
//   sessionStorage.setItem('token', JSON.stringify(userToken));
// }

function getToken() {
  // const tokenString = sessionStorage.getItem('token');
  // const userToken = JSON.parse(tokenString);
  // return userToken?.token
}


const App = () =>{
 


return (
  <div className='App'>
      {/* <BrowserRouter basename='/app'> */}
      <BrowserRouter>
        <Header />
        <div className="pages">
            <Routes>
              <Route path='/' element={<Home   />} />
              <Route path='/dashbrd' element={<Dashboard  />} />
              <Route path='/login' element={<Login />} />
              <Route path='/ptrade/' element={<PrimeTrade  />} />
              <Route path='/explore-item/:code' element={<ExploreItem  />} />
              <Route path='/item-modal' element={<ItemExploreModal  />} />
              <Route path='/mjr-trade/' element={<MajorTrade   />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/edit/:id' element={<Edit />} />
              <Route path='/view/:id' element={<View />} />
            </Routes>
        </div>
          {/* <div className='container'>   
          <Routes>
            <Route path='/dashbrd' element={<Dashboard  />} />
            <Route path='/login' element={<Login />} />
            <Route path='/home/' element={<Home   />} />
            <Route path='/ptrade/' element={<PrimeTrade  />} />
            <Route path='/mjr-trade/' element={<MajorTrade   />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/edit/:id' element={<Edit />} />
            <Route path='/view/:id' element={<View />} />
          </Routes>
           </div>  */}

      </BrowserRouter>
           
    </div>
  )
};
  

export default App;
